const formatData = data => {
    const drivers = driversData => {
        return driversData.map(driver => {
            return {
                ...driver,
                phone: driver.mobile,
                CountryCode: driver?.mobileCountryCode ? `${driver?.mobileCountryCode}` : '',
                yearsLicenseHeld: driver?.yearsLicenseHeld ?? '',
                licenseID: driver.licenseId,
                licenseDateIssued_year: driver.date_issued_year,
                licenseDateIssued_month: driver.date_issued_month,
                licenseDateIssued_day: driver.date_issued_day,
                useage: driver.percentageUsed,
                title: driver.title,
                grade: Number(driver.grade_notes.substr(driver.grade_notes.length - 1)),
            };
        });
    };

    let paymentFrequency = '';
    let numberOfPayments = 0;
    let eachPaymentAmount = '';

    if (data?.payment?.paymentOption.paymentOptionValue.indexOf('Monthly') !== -1) {
        paymentFrequency = 'Monthly';
        numberOfPayments = 12;
        eachPaymentAmount = data?.quoteData?.Month;
    } else if (data?.payment?.paymentOption.paymentOptionValue.indexOf('Fortnightly') !== -1) {
        paymentFrequency = 'Fortnightly';
        numberOfPayments = 25;
        eachPaymentAmount = data?.quoteData?.Fortnightly;
    } else if (data?.payment?.paymentOption.paymentOptionValue.indexOf('Annual') !== -1) {
        paymentFrequency = 'Annual';
        numberOfPayments = 1;
        eachPaymentAmount = data?.quoteData?.Annual;
    } else if (data?.payment?.paymentOption.paymentOptionValue.indexOf('Weekly') !== -1) {
        paymentFrequency = 'Weekly';
        numberOfPayments = 50;
        eachPaymentAmount = data?.quoteData?.Week;
    }

    const lastPaymentDate = new Date(data?.payment?.firstPaymentDate);
    lastPaymentDate.setMonth(lastPaymentDate.getMonth() + 11);

    const paymentDetails = {
        annualInstallmentFee: data?.quoteData?.Annual,
        monthlyInstallmentFee: data?.quoteData?.Month,
        fortnightInstallmentFee: data?.quoteData?.Fortnightly,
        weeklyInstallmentFee: data?.quoteData?.Week,
        eachPaymentAmount,
        paymentFrequency,
        numberOfPayments,
        firstPaymentDate: data?.payment?.paymentOption?.firstPaymentDate ?? '',
        administrationFee: data?.quoteData?.administrationFee,
        totalAdvances: data?.quoteData?.totalAdvances,
        interestCharges: data?.quoteData?.interestCharges,
        totalToPay: data?.quoteData?.totalToPay,
        notes: data?.payment?.notes,
        bankDetails: {
            nameOfBankAccount: data?.payment?.paymentOption?.accountName ?? '',
            bank: data?.payment?.paymentOption?.bankName ?? '',
            banBank: data?.payment?.paymentOption?.bankDetail?.bank
                ? `${data?.payment?.paymentOption?.bankDetail?.bank}`
                : '',
            banBranch: data?.payment?.paymentOption?.bankDetail?.branch
                ? `${data?.payment?.paymentOption?.bankDetail?.branch}`
                : '',
            banAccountNumber: data?.payment?.paymentOption?.bankDetail?.accountNumber
                ? `${data?.payment?.paymentOption?.bankDetail?.accountNumber}`
                : '',
            banSuffix: data?.payment?.paymentOption?.bankDetail?.suffix
                ? `${data?.payment?.paymentOption?.bankDetail?.suffix}`
                : '',
        },

        lastPaymentDate: data?.payment?.firstPaymentDate
            ? new Date(lastPaymentDate.getTime() - lastPaymentDate.getTimezoneOffset() * 60000)
                  .toISOString()
                  .split('T')[0]
            : null,
    };

    console.log('DATA__NEW', data);

    return {
        quote_id: data?.quote_id || null,
        dealer_id: data?.dealer_id || null,
        dealer_code: data?.dealer_code || 1,
        representedDealer: data?.notice?.representedDealer || null,
        criminalRecord: data?.notice?.criminalRecord || false,
        insuredLastFiveYears: data?.notice?.insuredLastFiveYears || false,
        vehicleType: data?.notice?.vehicleType || false,
        vehicle:
            {
                // informationType:
                //     data.details?.vehicleInformation?.informationType.hasInformationType || null,
                identityNumber:
                    data.details?.vehicleInformation?.informationType.identityNumber || null,
                year: data.details?.vehicleInformation?.informationType.year || null,
                make: data.details?.vehicleInformation?.informationType.make || null,
                model: data.details?.vehicleInformation?.informationType.model || null,
                registration:
                    data.details?.vehicleInformation?.informationType.registration || null,
                type: data.details?.vehicleInformation?.informationType.type || null,
                ccRating: data.details?.vehicleInformation?.informationType.ccRating || null,
                purchasePrice:
                    data.details?.vehicleInformation?.informationType.purchasePrice || null,
                storage: data.details?.quoteInformation?.storage.storageType || null,
                storageDetails: data.details?.quoteInformation?.storage.storageDetails || null,
                turbo: data.details?.quoteInformation?.hasTurbo.hasTurboValue || false,
                fittings: data.details?.quoteInformation?.fittings || null,
                modifications:
                    data.details?.quoteInformation?.modificationDetails?.modificationOverAGrand ||
                    null,
                modificationsNote:
                    data.details?.quoteInformation?.modificationDetails?.modificationOverAGrand?.indexOf(
                        'Other'
                    ) !== -1
                        ? data.details?.quoteInformation?.modificationDetails
                              .modificationOverAGrandNote || null
                        : null,
                financeCompany:
                    data.details?.quoteInformation?.financingCompanyDetails?.financingCompany ===
                    'Please choose a financing company'
                        ? data.details?.quoteInformation?.financingCompanyDetails
                              ?.otherFinancingCompany
                        : data.details?.quoteInformation?.financingCompanyDetails?.financingCompany,
            } || {},
        policyStartDate: data?.policy?.policyStartDate || null,
        policy_type: data?.policy?.policyType.policyTypeValue || null,
        roadsideAssistance: !!data?.policy?.roadsideAssistance.length || false,
        drivers: data?.ownership ? drivers(data?.ownership) : [],
        accidentsOrLosses:
            data?.disclosures?.anyAccidentsOrLosses.anyAccidentsOrLossesRequired || false,
        accidentsOrLosses_details:
            data?.disclosures?.anyAccidentsOrLosses.anyAccidentsOrLosses_details || null,
        specialConditions: data?.disclosures?.specialConditions.specialConditionsRequired || false,
        specialConditions_details:
            data?.disclosures?.specialConditions.specialConditions_details || null,
        licenceEndorsement:
            data?.disclosures?.licenceEndorsement.licenceEndorsementRequired || false,
        licenceEndorsement_details:
            data?.disclosures?.licenceEndorsement.licenceEndorsement_details || null,
        convictionsOrFines:
            data?.disclosures?.convictionsOrFines.convictionsOrFinesRequired || false,
        convictionsOrFines_details:
            data?.disclosures?.convictionsOrFines.convictionsOrFines_details || null,
        criminalActivity: data?.disclosures?.criminalActivity.criminalActivityRequired || false,
        criminalActivity_details:
            data?.disclosures?.criminalActivity.criminalActivity_details || null,
        insuranceDeclined: data?.disclosures?.insuranceDeclined.insuranceDeclinedRequired || false,
        insuranceDeclined_details:
            data?.disclosures?.insuranceDeclined.insuranceDeclined_details || null,
        furtherInformation:
            data?.disclosures?.anyFurtherInformation.anyFurtherInformationRequired || false,
        furtherInformation_details:
            data?.disclosures?.anyFurtherInformation.anyFurtherInformation_details || null,
        lastInsurer: data?.disclosures?.insuranceLast12Months,
        paymentDetails,
    };
};

export default formatData;
